import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { NotifierModule } from 'angular-notifier';
import { MatCardModule, MatDividerModule, MatProgressSpinnerModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDeleteComponent } from './confirm-delete.component';

import { PeriodFormatterPipe } from './period-formatter.pipe';
import { FilterByNamePipe } from './filter-by-name.pipe';

import { ShowAuthedDirective } from './show-authed.directive';

// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { Router, RouterModule } from '@angular/router';
import { AddressAutoCompleteComponent } from './address-auto-complete/address-auto-complete.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { CouncilStatsComponent } from './council-stats/council-stats.component';
import { CamelToNormalCasePipe } from './camel-to-normal-case.pipe';
import { BoxPlotComponent } from './box-plot/box-plot.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponent } from './google-map/google-map.component';
import { BindataComponent } from './bindata/bindata.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { ConfirmWithInputComponent } from './confirm-with-input/confirm-with-input.component';

@NgModule({
  declarations: [
    ConfirmDeleteComponent,
    PeriodFormatterPipe,
    FilterByNamePipe,
    ShowAuthedDirective,
    BreadcrumbComponent,
    AddressAutoCompleteComponent,
    DateRangePickerComponent,
    CouncilStatsComponent,
    CamelToNormalCasePipe,
    BoxPlotComponent,
    GoogleMapComponent,
    BindataComponent,
    PowerBiComponent,
    CreatePasswordComponent,
    ConfirmWithInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MomentModule,
    FontAwesomeModule,
    MatCardModule,
    MatDividerModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    RouterModule,
    GoogleMapsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MomentModule,
    FontAwesomeModule,
    ConfirmDeleteComponent,
    PeriodFormatterPipe,
    FilterByNamePipe,
    ShowAuthedDirective,
    MatCardModule,
    MatDividerModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    BreadcrumbComponent,
    GoogleMapsModule,
    AddressAutoCompleteComponent,
    DateRangePickerComponent,
    CamelToNormalCasePipe,
    CouncilStatsComponent,
    BoxPlotComponent,
    GoogleMapComponent,
    BindataComponent,
    PowerBiComponent,
    CreatePasswordComponent,
    ConfirmWithInputComponent
  ],
  entryComponents: [ConfirmDeleteComponent]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas);
  }
}
