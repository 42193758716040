import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-delete',
  template: `
  <div class="modal-header">
    <h4 class="modal-title text-danger">
      <fa-icon [icon]="['fas','exclamation-triangle']"></fa-icon> {{title}}</h4>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message"></p>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus  class="btn btn-light" (click)="activeModal.dismiss()">
      <fa-icon [icon]="['fas','times']"></fa-icon> No - Cancel</button>
    <button type="button" class="btn btn-danger" (click)="activeModal.close()">
      <fa-icon [icon]="['fas','trash']"></fa-icon>{{buttonText}}</button>
  </div>
  `,
  styles: []
})
export class ConfirmDeleteComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() buttonText : string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}
