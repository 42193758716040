import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  private setHeaders(notJSON?: boolean): HttpHeaders {
    
    var headersConfig = {};
    if (!notJSON) {
      headersConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
    }

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

  // private formatErrors(error: any) {
  //   return Observable.throw(error.json());
  // }

  get(path: string, params = {}): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}${path}`, { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  getDelay(path: string, params = {}): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}${path}`, { headers: this.setHeaders(), params: params }).pipe(timeout(300000));
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }


  put(path: string, body = {}, params = {}): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  post(path: string, body = {}, params = {}): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  patch(path: string, body = {}, params = {}): Observable<any> {
    return this.http.patch(`${environment.apiBaseUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  delete(path): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}${path}`, { headers: this.setHeaders() });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  googleMaps(): Observable<any>{
    return this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDtTzIPXTXSOzLXZtkEFjNP57uj20ePm7k', 'callback')
  }

  postUpload(path: string, body: any, params = {}): Observable<any>{
    return this.http.post<any>(`${environment.apiBaseUrl}${path}`, body, {  
      reportProgress: true,  
      observe: 'events'  ,
      headers: this.setHeaders(true),
      params: params
    });
  }
}
