
<div class="row">
    
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-hover table-striped table-sm">
                <thead>
                    <tr class="active">
                        <th>index</th>
                        <th>Address</th>
                        <th *ngIf="highestCurrentUserRole==='globalAdmin'">Council</th>
                        <th>Bintype</th>
                        <th>Serial Number</th>
                        <th>Sensor Type</th>  
                        <th>Sensor Location Status</th> 
                        <th>Temperature 1</th>
                        <th>Temperature 2</th>                      
                        <th>Binlevel</th>                      
                        <th>Gas reading</th> 
                        <th>Recorded At</th>                        
                        <th style="min-width:100px"></th>
                    </tr>
                </thead>
                <tbody class="client small ui-sortable cursor-pointer">
                    <tr *ngIf="binData.length===0">
                        <td [attr.colspan]="(highestCurrentUserRole==='globalAdmin')?13:12">No bin data records to display</td>
                    </tr>
                    <!-- <tr [routerLink]="(selectedCouncilUser.councilId===council.id || highestCurrentUserRole==='globalAdmin')?[council.id]:[]"
                        
                        [ngClass]="{'table-primary':(selectedCouncilUser.councilId===council.id && highestCurrentUserRole!=='globalAdmin')}"> -->
                    <tr *ngFor="let record of binData ; let i=index;">
                        <td >{{i+1}}</td>
                        <td [routerLink]="['/locations',record.sensorLocation.location.id]">{{composeAddress(record.sensorLocation.location)}}</td>
                        <td [routerLink]="['/councils',record.sensorLocation.location.council.id]" *ngIf="highestCurrentUserRole==='globalAdmin'">
                            {{record.sensorLocation.location.council?record.sensorLocation.location.council.name:'N/A'}}
                        </td>
                        <td [routerLink]="['/locattions',record.sensorLocation.location.id]">{{record.sensorLocation.binType}}</td>
                        <td [routerLink]="['/sensors',record.sensorLocation.sensor.id]">{{record.sensorLocation.sensor.serialNumber}}</td>
                        <td [routerLink]="['/sensors',record.sensorLocation.sensor.id]">{{record.sensorLocation.sensor.sensorType}}</td>
                        <td [routerLink]="['/sensors',record.sensorLocation.sensor.id]">{{record.sensorLocation.status}}</td>
                        <td >{{record.temperature1}}</td>
                        <td >{{record.temperature2}}</td>
                        <td >{{record.binLevel}}</td>
                        <td >{{record.gasReading}}</td>                        
                        <td >{{record.created |date : 'dd/MM/yyyy hh:mm a'}}</td>
                        <td>
                            <div class="btn-group row-options btn-group-sm">
                                <!-- <a class="btn btn-ligth " 
                                    ngbTooltip="Edit sensor">
                                    <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
                                </a> -->
                                <!-- <a class="btn btn-ligth " ngbTooltip="Re-send invitation"
                                    [hidden]="user.userAccounts[0].accountUser.lastLogin"
                                    >
                                    <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
                                </a>-->
                                <!-- <a class="btn btn-ligth btn-xs btn-red-hover" (click)="confirmArchiveSensor(sensor)" ngbTooltip="Archive sensor">
                                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                                </a> -->

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>