import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { SettingsUploadComponent } from './settings-upload/settings-upload.component';
import { AuthGuard } from '../core/services';
import { UserRole } from '../shared/models/enums';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      permission: [UserRole.GlobalAdmin],
    },
    // children: [
    //   { path: '', component: SettingsUploadComponent, canActivate: [AuthGuard], data:{ breadcrumb: 'settings'} }
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
