import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    ForbiddenComponent
  ],
  imports: [
    RouterModule,
    DashboardModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    HomeComponent,
    NotFoundComponent
  ]
})
export class CoreModule { }
