import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import * as cryptoJs from 'crypto-js';
import { ConfirmDeleteComponent } from 'src/app/shared/confirm-delete.component';
import { ConfirmWithInputComponent } from 'src/app/shared/confirm-with-input/confirm-with-input.component';
import { AuthenticationService } from '../../core/services';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styles: []
})
export class SigninComponent implements OnInit {

  authForm: FormGroup;
  isSubmitting = false;
  errors: string[];
  private notifier;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private modalService : NgbModal,
    notifierService : NotifierService
  ) { this.notifier = notifierService; }

  ngOnInit() {
    this.authForm = this.fb.group({
      'usernameOrEmail': ['', Validators.required],
      'password': ['', Validators.required]
    });

    this.authForm.valueChanges.subscribe((data) => {
        this.errors = null;
    })
  }

  submitForm() {
    this.isSubmitting = true;
    this.errors = undefined;
    const credentials = this.authForm.value;

    this.authenticationService
      .attemptAuth(credentials)
      .subscribe(
        data => this.router.navigateByUrl('/'),
        err => {
          this.errors = err;
          this.isSubmitting = false;
        }
      );
  }

  forgetPassword(){
    console.log('test')
    const modalRef = this.modalService.open(ConfirmWithInputComponent, { size: 'sm' });
    modalRef.componentInstance.title = 'Forget password?';
    modalRef.componentInstance.buttonText = 'Submit';
    modalRef.componentInstance.message = `Enter your email or username. Password reset ink will be sent out to your regsitered email account`;
    modalRef.componentInstance.valueSubmitted.subscribe((emailOrUserName)=>{
      if(emailOrUserName){
        this.requestPasswordReset(emailOrUserName)
      } 
    })
  }
   
  requestPasswordReset(emailOrUserName){
    this.authenticationService.passwordReset(emailOrUserName).subscribe(
      (res)=>{
        console.log(res);
        this.notifier.notify('success','request submitted');
      },
      (err)=>{
        this.notifier.notify('error', err.message);
      }
    )
  }
}
