import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToNormalCase'
})
export class CamelToNormalCasePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(value: string) {
    if ((typeof value) !== 'string') {
    return value;
    }
    value = value.split(/(?=[A-Z])/).join(' ');
    value = value[0].toUpperCase() + value.slice(1);
    return value;
    }
}
