import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from 'src/app/core/services';
import { BinData } from '../models/BinData';
import { CouncilUser } from '../models/CouncilUsers';
import { UserRole } from '../models/enums';
import { Location } from '../models/Location';

@Component({
  selector: 'app-bindata',
  templateUrl: './bindata.component.html',
  styles: [
  ]
})
export class BindataComponent implements OnInit {
  @Input() binData : BinData[];
  private selectedCouncilUser: CouncilUser;
  highestCurrentUserRole: UserRole;
  private notifier: NotifierService;
  constructor(
    private notifierService: NotifierService,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.selectedCouncilUser = this.authService.currentUserValue.selectedCouncilUser;
    this.highestCurrentUserRole = this.selectedCouncilUser.roles[0]
  }

  composeAddress(location:Location){
    return `${(location.unitNumber)?location.unitNumber+', ':''} ${location.streetNumber}
    ${location.streetName} ${location.suburb} ${location.postcode} ${location.state}`
  }

}
