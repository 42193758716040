import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { HomeComponent } from '../home/home.component';
import * as _ from 'lodash';
import { UserRole } from 'src/app/shared/models/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.authenticationService.isAuthenticated.pipe(
      take(1),
      map(result => {
        if (!result) {
          this.router.navigate(['/auth/signin']);
        } else {
          
          let currentUserRoles = this.authenticationService.currentUserValue.selectedCouncilUser.roles;
          if (route.data.permission) {
            if (this.allowUser(currentUserRoles, route.data.permission)) {
              return true;
            } else {
              this.router.navigate(['forbidden']);
              return false;
            }
          } else {
            return false;
          }

        }

      }),
      catchError(err => {
        console.error(err);
        this.router.navigate(['/auth/signin']);
        return of(false);
      })
    );

  }
  /**
   * 
   * @param userRoles 
   * @param routePermissions 
   */
  allowUser(userRoles, routePermissions) {
    let allow = false;
    _.forEach(userRoles, (userRole) => {
      if (_.find(routePermissions, (routePermission) => { return routePermission === userRole })) {
        return allow = true;
      }
    })
    return allow;

  }
}
