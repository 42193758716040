import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-truck-driver-dashboard',
  templateUrl: './truck-driver-dashboard.component.html',
  styles: [
  ]
})
export class TruckDriverDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
