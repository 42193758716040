<form class="form-inline datepicker">
    <div class="form-group">
      <div class="input-group input-group-sm">
        <label for="datepicker" class="pr-3" *ngIf="inputLabel">Select Date Range: </label>
        <input id="datepicker" class="form-control" name="dp" ngbDatepicker #d="ngbDatepicker" [autoClose]="false"
          [placeholder]="datePlaceHolder" [displayMonths]="2" [dayTemplate]="t" [maxDate]="maxDate" [startDate]="fromDate"
          (dateSelect)="onDateSelection($event)" [(ngModel)]="dateRange" [placement]="'bottom-right'">
        <div class="input-group-append">
          <button class="btn btn-primary" (click)="d.toggle()" type="button">
            <fa-icon [icon]="['fas','calendar-alt']" size="lg" [spin]="iconSpin"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-template #t let-date="date" let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </form>
