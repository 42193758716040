import { getAllLifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private apiService:ApiService) { }

  postReport(body):Observable<any>{
    return this.apiService.postUpload('/api/dashboard/report-publish',body);
  }
}