///<reference types="@types/googlemaps" />
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';



@Component({
  selector: 'app-address-auto-complete',
  templateUrl: './address-auto-complete.component.html',
  styles: [
  ]
})
export class AddressAutoCompleteComponent implements OnInit, AfterViewInit {
  @Input() addressType: string;
  @Input() autoUpdate : boolean;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: ElementRef;
  place : google.maps.places.PlaceResult;
  autocompleteInput: string;
  queryWait: boolean;
  showIcon : boolean =false

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.getPlaceAutoComplete();
  }

  private getPlaceAutoComplete() {
    if(this.addresstext){
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
          componentRestrictions: {country: 'AU'  },
          types: [this.addressType]
        });
  
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        place.address_components
        this.invokeEvent(place);
  
      })
    }
   
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.place = place;
    this.showIcon = true;
    if(this.autoUpdate){
      this.setAddress.emit(this.place);
    }
  }
  syncAddress() {
    this.setAddress.emit(this.place);
  }
}
