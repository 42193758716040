import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../../shared/models';
import { JwtService } from './jwt.service';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { TokenType } from 'src/app/shared/models/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  /**
   * 
   * @param apiService 
   * @param jwtService 
   */
  constructor(private apiService: ApiService, private jwtService: JwtService) {
  }

  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('/api/auth/user')
        .subscribe(
          data => {
            data['token'] = window.localStorage['token'];
            this.setAuth(data)
          },
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  attemptAuth(credentials): Observable<User> {
    return this.apiService.post('/api/auth/signin', credentials)
      .pipe(
        map((data: any) => {
          this.setAuth(data.user);
          return data;
        })
      );
  }

  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new User());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  // getAll(queryParams = {}): Observable<User[]> {
  //   return this.http.get<User[]>(`${environment.apiBaseUrl}/users`, { params: queryParams });
  //     // .pipe(
  //     //   map(users => users.map(e => Object.assign(new User(), e)))  // TODO: find out if this is necessary/beneficial
  //     // );
  // }

  // get(id: number): Observable<User> {
  //   return this.http.get<User>(`${environment.apiBaseUrl}/users/${id}`);
  // }

  // create(employee: User): Observable<User> {
  //   return this.http.post<User>(`${environment.apiBaseUrl}/users`, employee);
  // }

  // update(employee: User): Observable<User> {
  //   return this.http.put<User>(`${environment.apiBaseUrl}/users/${employee.id}`, employee);
  // }

  // delete(id: number): Observable<User> {
  //   return this.http.delete<User>(`${environment.apiBaseUrl}/users/${id}`);
  // }

  // getByEmail(email: string): Observable<User> {
  //   return this.http.get<User>(`${environment.apiBaseUrl}/users/by-email/${email}`);
  // }

  verifyToken(token: string, tokenType: string): Observable<boolean> {
    let params = new HttpParams()
      .set("tokenType", tokenType)
    return this.apiService.get(`/api/auth/invite/${token}`, params);
  }

  createPassword(token: string, tokenType: string, credentials): Observable<boolean> {
    let params = new HttpParams()
      .set("tokenType", tokenType)
    if(tokenType === TokenType.InviteToken){
      return this.apiService.post(`/api/auth/invite/${token}`, credentials, params);
    }else{
      return this.apiService.post(`/api/auth/reset/${token}`, credentials, params);
    }
    
  }

  passwordReset(emailOrUsername): Observable<any>{
    return this.apiService.post('/api/auth/reset-password',{emailOrUsername});
  }
}
