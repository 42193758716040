<div class="col-md-6 offset-md-3 col-xs-12 bg-white p-3 rounded">
  <div class="col-md-6 offset-md-3 col-xs-12 text-center ">
    <img src="../../../assets/images/Binsense_logo.png" alt="..." class=" border-0">
  </div>



  <div class="col-md-8 offset-md-2 ">
    <h3 class="text-xs-center mb-4">Sign In</h3>
    <form [formGroup]="authForm" (ngSubmit)="submitForm()">
      <fieldset [disabled]="isSubmitting">
        <div class="form-group">
          <input formControlName="usernameOrEmail" placeholder="Username or Email" class="form-control form-control-lg"
            type="text" />
        </div>
        <div class="form-group">
          <input formControlName="password" placeholder="Password" class="form-control form-control-lg"
            type="password" />
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="errors">
          {{errors}}
        </div>

        <button class="btn btn-lg float-right btn-primary pull-xs-right" [disabled]="!authForm.valid" type="submit">
          Sign In
        </button>
        <a (click)="forgetPassword()"class="text-primary float-right mt-3 pt-1 mr-2">Forget Password</a>



      </fieldset>
    </form>

  </div>

</div>