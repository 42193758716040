import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { ConfigModule } from './config/config.module';

import { AppComponent } from './app.component';
import { SettingsModule } from './settings/settings.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AuthenticationService, AuthGuard } from './core/services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/services/error-interceptor';
// import { TimesheetsModule } from './timesheets/timesheets.module';
// import { ClientsModule } from './clients/clients.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BindataModule } from './bindata/bindata.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NotifierModule } from 'angular-notifier';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,    
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    ConfigModule,
    SettingsModule,
    AuthModule,
    CoreModule,
    NotifierModule.withConfig({
      position :{
        horizontal:{
          position: 'right',
          distance :12
        },
        vertical :{
          position:'top',
          distance : 12,
          gap:10
        }
      },
      behaviour:{
        autoHide :5000
      }
    }),
    DashboardModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule  

  ],
  providers: [AuthenticationService,AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
