import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { CouncilService } from 'src/app/council/services/council.service';

@Component({
  selector: 'app-council-stats',
  templateUrl: './council-stats.component.html',
  styles: [
  ]
})
export class CouncilStatsComponent implements OnInit {
  loading =true;
  data : any;
  private notifier: NotifierService;

  constructor(
    private councilService : CouncilService,
    notifierService: NotifierService,
  ) { this.notifier= notifierService;}

  ngOnInit(): void {
    this.councilService.getCouncilStats().subscribe(
      (res)=>{
        this.data =res['data'];
      },(err)=>{
        this.notifier.notify('error',err.message)

      },()=>{
        this.loading = false;
      }
    )
  }

  issObject(value:Object|string){
    return typeof value === 'object';
  }
  returnZero (){
    return 0;
  }

}
