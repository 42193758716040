import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { SettingsUploadComponent } from './settings-upload/settings-upload.component';

import { SettingsDataService } from './settings-data.service';

@NgModule({
  declarations: [
    SettingsComponent,
    SettingsUploadComponent
  ],
  imports: [
    SharedModule,
    SettingsRoutingModule
  ],
  providers: [SettingsDataService]
})
export class SettingsModule { }
