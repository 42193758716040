import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateRange } from '../models/interfaces';
import * as moment from 'moment';
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls:['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DateRangePickerComponent implements OnInit,OnChanges {
  @Input() from?: moment.Moment;
  @Input() to?: moment.Moment;
  @Input() inputLabel = 'Select Date Range';
  @Input() icon = ['fas', 'calendar-edit'];
  @Input() iconSpin = false;

  @Output() dateRangeSelected = new EventEmitter<DateRange>();

  @ViewChild('d') datePicker: NgbInputDatepicker;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  dateRange: NgbDateStruct;
  datePlaceHolder = '';
  constructor(public calendar: NgbCalendar, private formatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    if (this.from && this.to) {
      this.datePlaceHolder = `${this.from.format('DD/MM/YYYY')} - ${this.to.format('DD/MM/YYYY')}`;
      this.fromDate = this.formatter.parse(this.from.format('YYYY-MM-DD'));
      this.toDate = this.formatter.parse(this.to.format('YYYY-MM-DD'));
    }

    this.maxDate = this.calendar.getToday();
  }

  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.from && changes.from.currentValue && changes.to && changes.to.currentValue) {
      this.datePlaceHolder = `${this.from.format('DD/MM/YYYY')} - ${this.to.format('DD/MM/YYYY')}`;
      this.fromDate = this.formatter.parse(this.from.format('YYYY-MM-DD'));
      this.toDate = this.formatter.parse(this.to.format('YYYY-MM-DD'));
    }

    this.maxDate = this.calendar.getToday();
  }

  /**
   * Update the dates from the new selection.
   * @param date new date selected
   */
  onDateSelection(date: NgbDateStruct) {

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (this.after(date, this.fromDate) || this.compareDates(date, this.fromDate))) {
      this.toDate = date;

      const dates = {
        fromDate: moment(this.formatter.format(this.fromDate)).startOf('day'),
        toDate: moment(this.formatter.format(this.toDate)).endOf('day')
      };
      // emit the values
      this.dateRangeSelected.emit(dates);

      // clear the model and update the placeholder so that the range of dates is visible
      this.dateRange = null;
      this.datePlaceHolder = `${dates.fromDate.format('DD/MM/YYYY')} - ${dates.toDate.format('DD/MM/YYYY')}`;

      // close the picker
      this.datePicker.close();

    } else {
      this.toDate = null;
      this.fromDate = date;
      // this.dateRange = `${this.startTime.format('DD/MM/YYYY')}`;
    }
  }

  /**
   * This is to check if the new selected date is equal to the already selected day.
   * @param date new date selection
   * @param fromDate the starting/from date
   */
  compareDates(date, fromDate) {
    if (date.year === fromDate.year &&
      date.month === fromDate.month &&
      date.day === fromDate.day) {
      return true;
    }
    return false;
  }


  equals = (one: NgbDateStruct, two: NgbDateStruct) =>
    one && two && two.year === one.year && two.month === one.month && two.day === one.day

  before = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day < two.day : one.month < two.month : one.year < two.year

  after = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day > two.day : one.month > two.month : one.year > two.year

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && this.after(date, this.fromDate)
    && this.before(date, this.hoveredDate)

  isInside = date => this.after(date, this.fromDate) && this.before(date, this.toDate);
  isFrom = date => this.equals(date, this.fromDate);
  isTo = date => this.equals(date, this.toDate);
}
