import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { BinData } from 'src/app/shared/models/BinData';

import { DateRange } from '../../shared/models/interfaces';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-council-admin-dashboard',
  templateUrl: './council-admin-dashboard.component.html',
  styles: [
  ]
})
export class CouncilAdminDshboardComponent implements OnInit {
  startTime: moment.Moment;
  endTime: moment.Moment;
  loading1: boolean = true;
  loading2: boolean = true;
  boxPlotData: any;
  bindataTableData : BinData[];
  private notifier: NotifierService;
  constructor(
    private dashboardService: DashboardService,
    notifierService: NotifierService)
  { this.notifier = notifierService; }

  ngOnInit(): void {
    this.startTime = moment().subtract(1, 'months');
    this.endTime = moment();
    this.fetchBoxPlotData();
    this.fetchBinData();
  }

  updateDateRange(dateRange: DateRange) {
    this.startTime = dateRange.fromDate;
    this.endTime = dateRange.toDate;
    this.fetchBoxPlotData();
    this.fetchBinData();
  }

  fetchBoxPlotData() {
    this.dashboardService.getBoxPlotData({ fromDate: this.startTime.format('YYYY-MM-DD'), toDate: this.endTime.format('YYYY-MM-DD') }).subscribe(
      (res) => {
        this.boxPlotData = res['data'];
      },
      (err) => {
        this.notifier.notify('error', err.message);
      },
      () => {
        this.loading1 = false;
      }
    )

  }

  fetchBinData() {
    this.dashboardService.getBinData({ fromDate: this.startTime.format('YYYY-MM-DD'), toDate: this.endTime.format('YYYY-MM-DD') }).subscribe(
      (res) => {
        this.bindataTableData = res['data'].binData;
      },
      (err) => {
        this.notifier.notify('error', err.message);
      },
      () => {
        this.loading2 = false;
      }
    )
  }
}
