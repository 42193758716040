<div class="modal-header">
    <h4 class="modal-title ">
       {{title}}</h4>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message"></p>
    <input [(ngModel)]="email"  class="form-control" type="text">
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus  class="btn btn-light" (click)="activeModal.dismiss()">
      <fa-icon [icon]="['fas','times']"></fa-icon>Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submit()">
      <fa-icon [icon]="['fas','check']"></fa-icon>{{buttonText}}</button>
  </div>
