import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models';
import { UserRole } from 'src/app/shared/models/enums';
import { AuthenticationService } from '../core/services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: []
})
export class SettingsComponent implements OnInit {
  private currentUser : User;
  highestCurrentUserRole : UserRole;
  constructor(private authService:AuthenticationService){}

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue
    this.highestCurrentUserRole = this.currentUser.selectedCouncilUser.roles[0]
    console.log(this.highestCurrentUserRole);
  }
}
