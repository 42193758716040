
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/services';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Location } from '../models/Location';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styles: [
  ]
})
export class GoogleMapComponent implements OnInit, AfterViewInit {

  @Input() location : Location;
  @ViewChild('googleMaps') googleMaps : ElementRef
  apiLoaded : Observable<boolean>

  center: google.maps.LatLngLiteral = {lat:24, lng: 12}
  zoom = 18;
  mapTypeId: google.maps.MapTypeId.SATELLITE
  marker : google.maps.Marker;
  infowindow : google.maps.InfoWindow

  constructor(private apiService:ApiService) {
    this.apiLoaded = this.apiService.googleMaps().pipe(
      map(() => true),
      catchError(() => of(false)),
    );
    

   }
  ngAfterViewInit(): void {
    this.center = {lat:this.location.latitude, lng:this.location.longitude};
    new google.maps.Marker({
      position:  {lat:this.location.latitude, lng:this.location.longitude},
    map:this.googleMaps['googleMap'],
      title: 'Sensor counts : '+this.location.sensorLocations.length
    })
   
  }



  ngOnInit(): void {
   
  }

}
