export enum UserRole{
    GlobalAdmin = 'globalAdmin',
    CouncilAdmin = 'councilAdmin',
    TruckDriver  = 'truckDriver',
    HouseOwner  = 'houseOwner'
}

export enum BinType{
    Fogo = 'fogo',
    Recyclable = 'recyclable',
    General = 'general' 
}

export enum SensorType{
    SensorType1 = 'type1',
    SensorType2 = 'type2'
}

export enum SensorStatus{
    Active = 'active',
    InActive = 'inactive'
}

export enum TokenType {
    InviteToken = 'inviteToken',
    ResetToken = 'resetToken'
}