import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services';
import { BinData } from 'src/app/shared/models/BinData';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private apiService:ApiService) { }
  
  getBoxPlotData(dates:{fromDate:string,toDate:string}):Observable<any>{
    return  this.apiService.get('/api/bin-data/box-plots',dates)   
  }
  getBinData(dates:{fromDate:string,toDate:string}):Observable<any>{
    return  this.apiService.get('/api/bin-data',dates)   
  }
  getEmbedToken():Observable<any>{
    return this.apiService.get('/api/dashboard/get-embed-token')
  }

}
