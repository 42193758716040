<main class="container-fluid">
  <div class="row">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Configuration</span>
        </h6>

        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['positions']" [routerLinkActive]="'active'">
              <fa-icon [icon]="['fas', 'sitemap']" [fixedWidth]="true"></fa-icon> Position Types</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['departments']" [routerLinkActive]="'active'">
              <fa-icon [icon]="['fas', 'building']" [fixedWidth]="true"></fa-icon> Departments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['competencies']" [routerLinkActive]="'active'">
              <fa-icon [icon]="['fas', 'award']" [fixedWidth]="true"></fa-icon> Competencies</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['licences']" [routerLinkActive]="'active'">
              <fa-icon [icon]="['fas', 'id-card']" [fixedWidth]="true"></fa-icon> Licences</a>
          </li>
        </ul>

      </div>
    </nav>

    <div class="col-md-9 ml-sm-auto col-lg-10 p-3">
      <router-outlet></router-outlet>
    </div>

  </div>
</main>