import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NotifierService } from 'angular-notifier';
import { find, forEach } from 'lodash';
import { AuthenticationService } from 'src/app/core/services';
import { TokenType } from 'src/app/shared/models/enums';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styles: []
})
export class InviteComponent implements OnInit {
  token: string;
  formType = TokenType.InviteToken
  private notifier: NotifierService;
  createPasswordForm: FormGroup;
  isSubmitting = false;
  errors = null;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    notifierService: NotifierService,
    private authService: AuthenticationService,
  ) {
    this.notifier = notifierService;

  }


  ngOnInit() {
 
  }





  submit(value:{password:string,token:string}) {
    this.authService.createPassword(value.token, TokenType.InviteToken, { password: value.password })
      .subscribe((res) => {
        this.notifier.notify('success', 'Password created successful please sign in')

        this.router.navigate(['/auth/signin']);
      }, (err) => {
        this.notifier.notify('error', err.message)
      }, () => {

      })

  }

  cancelled(){
    this.router.navigate(['/'])
  }


}
