import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from 'src/app/core/services';
import { TokenType } from 'src/app/shared/models/enums';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styles: [
  ]
})
export class ForgetPasswordComponent implements OnInit {
  token: string;

  private notifier: NotifierService;
  formType = TokenType.ResetToken
  createPasswordForm: FormGroup;
  isSubmitting = false;
  errors = null;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    notifierService: NotifierService,
    private authService: AuthenticationService,
  ) {
    this.notifier = notifierService;

  }


  ngOnInit() {
 
  }





  submit(value:{password:string,token:string}) {
    this.authService.createPassword(value.token, TokenType.ResetToken, { password: value.password })
      .subscribe((res) => {
        this.notifier.notify('success', 'Password created successful please sign in')

        this.router.navigate(['/auth/signin']);
      }, (err) => {
        this.notifier.notify('error', err.message)
      }, () => {

      })

  }

  cancelled(){
    this.router.navigate([''])
  }
}