<div class="form-group row ">
    <label for="council" class="col-sm-2 col-form-label text-md-right"> Location search:</label>
    <div class="col-sm-4">
        <input class="form-control" type="text" [(ngModel)]="autocompleteInput" #addresstext>
    </div>
    <div *ngIf="!autoUpdate" class="col-sm-2">
        <label for="council" class="col-form-label text-md-right"><a type="button" (click)="syncAddress()"
                title="sync address">
                <fa-icon [icon]="['fas', 'sync']" size="1x"></fa-icon>
            </a></label>
    </div>
</div>