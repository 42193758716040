import { AfterViewInit, Component, ElementRef, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import * as pbi from 'powerbi-client';
import { DashboardService } from '../../dashboard/services/dashboard.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'power-bi',
  templateUrl: './power-bi.component.html',
  styles: []
})
export class PowerBiComponent implements OnInit, AfterViewInit {
  // @Input() powerBiEmbedToken : any;
  private notifier : NotifierService;
  report: pbi.Embed;
  loading:boolean = true;
  loading3: boolean = true;
  powerBiToken: any;
  watermarkOpacity= 0.2;
  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;
  // margin = { top: 10, right: 10, bottom: 50, left: 50 };
  elementWidth: any;
  rendered = false;
  

  constructor(
    private dashboardService: DashboardService,
    notifierService: NotifierService
  ) {
      this.notifier = notifierService;
    }

  ngOnInit(): void {
    this.fetchEmbedToken();
  }

  ngAfterViewInit() {
    this.transitionWatermark()
  }

  ngOnChanges(changes: SimpleChanges) {}

  loadReport(){
    this.showReport(
      this.powerBiToken.embedUrl[0].reportId,
      this.powerBiToken.embedUrl[0].embedUrl,
      this.powerBiToken.reportSectionId,
      this.powerBiToken.slicerDefaults,
      this.powerBiToken.accessToken,
      this.powerBiToken.expiry
    );
  }

  fullscreen(){
    this.report.fullscreen();
  }

  reload(){
    var self = this;
    this.report['refresh']()
      .catch(function (err) {
        console.log(err);
        self.notifier.notify('error', "Error with loading Power BI Report: " + err  ['detailedMessage'])
      });
  }


  showReport(reportId,reportUrl,reportSectionId,slicerDefaults,token,expiry) {
    // Embed URL    
    let embedUrl = reportUrl;
    let embedReportId = reportId;
    let settings: pbi.IEmbedSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      background: pbi.models.BackgroundType.Transparent,
      layoutType: pbi.models.LayoutType.Custom,
      customLayout: {
        displayOption: pbi.models.DisplayOption.FitToPage
      }
    };
    let config = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: token,
      pageName: reportSectionId,
      permissions: pbi.models.Permissions.Read,
      viewMode: pbi.models.ViewMode.View,
      embedUrl: embedUrl,
      // pageView: "fitToWidth",
      id: embedReportId,
      // datasetBinding: {
      //   datasetId: datasetId
      // },
      filters: [],
      slicers: slicerDefaults,
      settings: settings
    };

    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.report = powerbi.embed(reportContainer, config);

    this.report.off("rendered");
    this.report.on("rendered", () => {
      this.loading = false;
    })

    this.report.off("loaded");
    this.report.on("loaded", () => {
      this.rendered = true;
      
      console.log("Loaded");
      this.setTokenExpirationListener(expiry, 2);
    });
    this.report.on("error", (err) => {
      console.log("Error");
      console.log(err);
      this.notifier.notify('error', "Error with loading Power BI Report: " + err.detail['detailedMessage'])
    });
  }

  setTokenExpirationListener(tokenExpiration, minutesToRefresh = 2){
    // get current time
    var currentTime = Date.now();
    var expiration = Date.parse(tokenExpiration);
    var safetyInterval = minutesToRefresh* 60 * 1000;
  
    // time until token refresh in milliseconds
    var timeout = expiration - currentTime - safetyInterval;
    var self = this;
    // if token already expired, generate new token and set the access token
    if (timeout<=0)
    {
        console.log("Updating Report Embed Token");
        self.updateToken();
    }
    // set timeout so minutesToRefresh minutes before token expires, token will be updated
    else 
    {
        console.log("Report Embed Token will be updated in " + timeout + " milliseconds.");
        setTimeout(function() { self.updateToken();}, timeout);
    }
  }
  
  updateToken() {
    var self = this;
    // Generate new EmbedToken
    self.dashboardService.getEmbedToken().subscribe(
      (res) => {
        self.powerBiToken = res;
        // Set AccessToken
        self.report.setAccessToken(self.powerBiToken.accessToken)
        .then(function() {
          // Set token expiration listener
          // result.expiration is in ISO format
          self.setTokenExpirationListener(self.powerBiToken.expiry,2 /*minutes before expiration*/);
        });
      },
      (err) => {
        self.notifier.notify('error', err.message);
      },
      () => {}
    )
  } 
  
  
  transitionWatermark(){
    setTimeout(() => {
        if(this.watermarkOpacity == 1) {
          this.watermarkOpacity = 0.2;
        }
        else {
          this.watermarkOpacity = 1
        }
        this.transitionWatermark();
    }, 1000);
  }


  fetchEmbedToken() {
    this.dashboardService.getEmbedToken().subscribe(
      (res) => {
        this.powerBiToken = res;
        this.loadReport()
      },
      (err) => {
        // verify if reportId returned is null
        if (err.message !== 'PowerBI Report not associated with account.') {
          this.notifier.notify('error', err.message);
        }
        this.loading3 = false;
        this.loading = false;
      },
      () => {
        this.loading3 = false;
      }
    )
  }

  
}

