import { AfterViewInit, Component, ElementRef, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';

@Component({
  selector: 'app-box-plot',
  templateUrl: './box-plot.component.html',
  styles: [
  ]
})
export class BoxPlotComponent implements OnInit, AfterViewInit {
  @Input() loading: boolean;
  @Input() data: any;
  @ViewChild('bplotContainer') boxPlotContainer: ElementRef;
  margin = { top: 10, right: 10, bottom: 50, left: 50 };
  elementWidth: any;

  constructor() {
    // console.log(this.boxPlotContainer)
    // this.elementWidth = this.boxPlotContainer.nativeElement.offsetWidth;
  }

  ngOnInit(): void {

    // console.log(this.boxPlotContainer)
    // this.elementWidth = this.boxPlotContainer.nativeElement.offsetWidth;
  }
  ngAfterViewInit() {
    this.elementWidth = this.boxPlotContainer.nativeElement.offsetWidth;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      let t1BoxColor = '#28a745';

      d3.select('#BPr1c1 svg').remove();
      d3.select('#BPr1c1 div').remove();

      d3.select('#BPr1c2 svg').remove();
      d3.select('#BPr1c2 div').remove();

      d3.select('#BPr1c3 svg').remove();
      d3.select('#BPr1c3 div').remove();


      let data1 = [{ key: 'Temp 01', value: { q1: 10, median: 20, q3: 30, min: 5, max: 60 } }, { key: 'Temp 02', value: { q1: 12, median: 30, q3: 45, min: 5, max: 50 } }]
      let data2 = [{ key: 'Bin Level', value: { q1: 100, median: 200, q3: 300, min: 5, max: 360 } }]

      let temperatureData = this.data['temperature'];
      if(temperatureData[0].value.q1){
        this.drawPlot(temperatureData, 0, 100, 1, 1, 'C', t1BoxColor);
      }else{
        d3.select('#BPr1c1 ').html('<div class="text-center"><h6>No Data</h6></div>');
      }

      let binLevel = this.data['binLevel'];
      if(binLevel[0].value.q1){
        this.drawPlot(binLevel, 0, 500, 1, 2, 'C', t1BoxColor);
      }else{
        d3.select('#BPr1c2 ').html('<div class="text-center"><h6>No Data</h6></div>');
      }

      let gasReadingData = this.data['gasReading'];
      if (gasReadingData[0].value.q1) {
        this.drawPlot(gasReadingData, 0, 100, 1, 3, 'C', t1BoxColor);
      } else {
        d3.select('#BPr1c3 ').html('<div class="text-center"><h6>No Data</h6></div>');
      }
      
      


    }
  }

  drawPlot(data: any, yMin, yMax, row, coloumn, unit, boxColor) {
    const chartHeight = 550 - this.margin.top - this.margin.bottom,
      // chartwidth = this.elementWidth - this.margin.right - this.margin.left;
      chartwidth = 200;
    const domains = _.map(data, (item) => {
      return item.key;
    })
    let svg = d3.select(`#BPr${row}c${coloumn}`)
      .append("svg")
      .attr("width", chartwidth + this.margin.right + this.margin.left)
      .attr("height", chartHeight + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    //create x 
    let xAxis = d3.scaleBand()
      .range([0, chartwidth])
      .domain(domains)
      .paddingInner(2)
      .paddingOuter(2);

    //create y
    let yAxis = d3.scaleLinear()
      .range([chartHeight, 0])
      .domain([yMin, yMax])
     
    svg.append("g").call(d3.axisLeft(yAxis))
    //append x axis
    let x = svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + chartHeight + ")")
      .call(d3.axisBottom(xAxis));

    //box
    let boxWidth = 20;
    let box = svg.selectAll("boxgroup")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "box");


    //append box
    box.append("rect")
      .attr("x", function (d) { return (xAxis(d['key']) - boxWidth / 2) })
      .attr("y", function (d) { return (yAxis(d['value'].q3)) })
      .attr("height", function (d) { return (yAxis(d['value'].q1) - yAxis(d['value'].q3)) })
      .attr("width", boxWidth)
      .attr("stroke", "black")
      .style("fill", boxColor)
      .on("mouseover", (event, d) => mouseover(event, d))
      .on("mouseleave", (d) => mouseleave());

    //vertical lines
    box.append("line")
      .attr("class", "verticalline")
      .attr("x1", (d) => { return xAxis(d['key']) })
      .attr("x2", (d) => { return xAxis(d['key']) })
      .attr("y1", (d) => { return yAxis(d['value'].min) })
      .attr("y2", (d) => { return yAxis(d['value'].max) })
      .attr("stroke", "black")
      .style("width", 25)
      .on("mouseover", (event, d) => mouseover(event, d))
      .on("mouseleave", (d) => mouseleave());

    //min horizontal
    box.append("line")
      .attr("class", "minhorizonal")
      .attr("x1", (d) => { return xAxis(d['key']) - boxWidth / 2 })
      .attr("x2", (d) => { return xAxis(d['key']) + boxWidth / 2 })
      .attr("y1", (d) => { return yAxis(d['value'].min) })
      .attr("y2", (d) => { return yAxis(d['value'].min) })
      .attr("stroke", "black")
      .style("width", 25)
      .on("mouseover", (event, d) => mouseover(event, d))
      .on("mouseleave", (d) => mouseleave());

    //max horzonatal line
    box.append("line")
      .attr("class", "maxhorizonal")
      .attr("x1", (d) => { return xAxis(d['key']) - boxWidth / 2 })
      .attr("x2", (d) => { return xAxis(d['key']) + boxWidth / 2 })
      .attr("y1", (d) => { return yAxis(d['value'].max) })
      .attr("y2", (d) => { return yAxis(d['value'].max) })
      .attr("stroke", "black")
      .style("width", 25)
      .on("mouseover", (event, d) => mouseover(event, d))
      .on("mouseleave", (d) => mouseleave());



    //add tooltip
    let tooltip = d3.select(`#BPr${row}c${coloumn}`)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip");

    //tooltip displays on mouseover
    let mouseover = function (event, d) {
      tooltip = d3.select(`#BPr${row}c${coloumn}`)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip");

      //tooltip html
      let htmlTag = `<table class="sm-table">
    <tbody>
    <tr>
    <th class="text-right">max:</th>
    <td>${d['value'].max}</td>
    </tr>
    <tr>
    <th class="text-right">q3:</th>
    <td>${d['value'].q3}</td>
    </tr>
    <tr>
    <th class="text-right">median:</th>
    <td>${d['value'].median}</td>
    </tr>
    <tr>
    <th class="text-right">q1:</th>
    <td>${d['value'].q1}</td>
    </tr>
    <tr>
    <th class="text-right">min:</th>
    <td>${d['value'].min}</td>
    </tr>
    </tbody>
    </table>`;
      tooltip
        .html(htmlTag)
        .style("top", (event.offsetY / 2) + "px")
        .style("left", (event.offsetX) + "px")
        .style("opacity", 1);
    }

    //remove tooltip on mouseleave
    let mouseleave = function () {
      d3.selectAll("div.tooltip").remove();
    }
  }



}
