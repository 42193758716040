import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { User } from '../../shared/models';
import { AuthenticationService } from '../services';
import * as _ from 'lodash';
import { UserRole } from 'src/app/shared/models/enums';
import { CouncilUser } from 'src/app/shared/models/CouncilUsers';
import { UserService } from 'src/app/users/shared/user.service';
import { NotifierService } from 'angular-notifier';
import { Location } from '@angular/common';
import * as d3 from 'd3';
// import { Role } from 'src/app/shared/models/enums';

export class MenuItem {
  subMenu?: MenuItem[];
  permission: UserRole[];
  path: string;
  title: string;
  position: number;
  icon?: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})

export class HeaderComponent implements OnInit{

  @Input() currentUser: User;

  private notifier : NotifierService;

  public navbarCollapsed = true;

  highestCurrentUserRole:UserRole;
  currentUserValue: User;
  menuItems: MenuItem[];
  loading : boolean = true;
  

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    notifierService : NotifierService,
    private location: Location) {
      this.notifier = notifierService;
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (user) => {
        this.currentUserValue = user;
        this.highestCurrentUserRole = this.currentUserValue?.selectedCouncilUser?.roles[0];
        this.buildMenu();
      }
    )
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigateByUrl('/auth/signin');
  }

  getMenuItems(): MenuItem[] {
    return this.router.config
      //filter out routes with position number and permission level
      .filter(route => route.data && route.data.position >= 0 && this.allowUser(this.currentUserValue.selectedCouncilUser?.roles, route.data.permission))
      .map(route => {
        return {
          permission: [route.data.permission],
          path: route.path,
          title: route.data.title,
          icon: route.data.icon,
          position: route.data.position
        };
      });
  }

  allowUser(userRoles, routePermissions) {
    let allow = false
    _.forEach(userRoles, (userRole) => {
      if (_.find(routePermissions, (routePermission) => { return routePermission === userRole })) {
        return allow = true;
      }
    })
    return allow;

  }

  buildMenu() {
    this.menuItems = this.getMenuItems();
    let portfolio = {
      permission: [UserRole.GlobalAdmin],
      path: null,
      title: 'Portfolio',
      icon: 'briefcase',
      position: 3.0
    }
    let reporting = {
      permission: [UserRole.GlobalAdmin],
      path: null,
      title: 'Reporting',
      icon: 'chart-line',
      position: 2.0
    }
    //adding static menu items to menu array
    // if (_.find(this.currentUser, (role) => { return role === 'admin' })) {
    //   this.menuItems.push(portfolio);
    //   this.menuItems.push(reporting);
    // }

    this.menuItems = _.sortBy(this.menuItems, 'position');
    _.forEach(this.menuItems, (menuItem, index) => {
      //skipping the removed subitmes
      if (!menuItem) {
        return;
      }
      let position = menuItem.position;
      let positionUp = position + 1;
      //remove subitems from main menu
      let subItems = _.remove(this.menuItems, (item) => {
        return item.position > position && item.position < positionUp;
      })
      //adding sorted submenu itmes to main menu
      this.menuItems[index].subMenu = _.sortBy(subItems, 'position');
      this.loading =false;
    })
  }

  changeCouncil(councilUser: CouncilUser) {
    this.userService.setCurrentCouncilUser(councilUser).subscribe(
      (res)=>{
        
        this.authService.setAuth(res['user']); 
      },(err)=>{
        this.notifier.notify('error',err.message)
      },()=>{
        this.router.navigate(['/']);      
        window.location.reload();
      }
    )
  }
}
