<div class="col-12 animate-show-hide" >
    <div class="card">
        <div class="card-header d-md-block">
            <div class="float-left">
                <h6>PowerBI</h6>
            </div>
            <div class="btn-group btn-group-sm float-right">
                <a *ngIf="highestCurrentUserRole==='globalAdmin' && basicInfo.workspaceId" class="btn btn-primary mx-1" target="_blank" href="https://app.powerbi.com/groups/{{basicInfo.workspaceId}}/list/dashboards">
                    <fa-icon class="px-1" [icon]="['fas', 'external-link-alt']"></fa-icon>Power BI Service Workspace
                </a>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group row">
                        <label class="col-form-label col-sm-3 text-md-right">PowerBI Configuration Details <fa-icon ngbTooltip="The settings configured on the web server" container="body" [icon]="['fas', 'info-circle']"></fa-icon></label> 
                        <div class="col-sm-4">
                            <dl>
                                <dt>Tenant ID <fa-icon class="cursor-pointer" ngbTooltip="The tenant ID is the ID of the account the application is tied to in Microsoft Azure (Office 365)" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.tenantId}}</dd>
                                <dt>Client ID <fa-icon class="cursor-pointer" ngbTooltip="The client ID is the ID of the application registered in Microsoft Azure (Office 365)" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.clientId}}</dd>
                                <dt>Workspace ID <fa-icon class="cursor-pointer" ngbTooltip="The workspace ID is the ID of the PowerBI workspace configured for housing all the reports and datasets used in the application" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.workspaceId}}</dd>
                                <dt>Configured Account <fa-icon class="cursor-pointer" ngbTooltip="The configured account used by the application for connecting to the PowerBI REST API" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.configuredAccount}}</dd>
                                <dt>File Upload Limit <fa-icon class="cursor-pointer" ngbTooltip="The upload limit for PBIX files to the application" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.uploadLimit / 1024 / 1024 | number:'1.2-2'}} MB</dd>
                                <dt>Token Update Schedule <fa-icon class="cursor-pointer" ngbTooltip="The automatic schedule for updating the security tokens in all the client PowerBI reports" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.scheduledTokenUpdate}}</dd>
                            </dl>
                        </div>

                        <div class="col-sm-4">
                            <dl>
                                <dt>Global Report Name <fa-icon class="cursor-pointer" ngbTooltip="The name of the global PowerBI report used by the Global Admin" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.globalReportName}}</dd>
                                <dt>Default Report Section <fa-icon class="cursor-pointer" ngbTooltip="The ID for the default tab in the PowerBI report to load" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.defaultReportSection}}</dd>
                                <dt>Report on Server <fa-icon class="cursor-pointer" ngbTooltip="The status of the global PowerBI report uploaded in the application" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.isFileExist}}</dd>
                                <dt>Report Size <fa-icon class="cursor-pointer" ngbTooltip="The file size of the global PowerBI report" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.fileSize / 1024 / 1024 | number:'1.2-2'}} MB</dd>
                                <dt>Report Last Modified <fa-icon class="cursor-pointer" ngbTooltip="The last modification date of the global PowerBI report" container="body" [icon]="['fas', 'info-circle']"></fa-icon></dt>
                                <dd>{{basicInfo.modifiedAt | date:'medium'}}</dd>
                            </dl>
                        </div>


                    </div>
                    <div class="form-group row">
                        <label class="col-form-label col-sm-3 text-md-right"> Upload New PBIX File to Server <fa-icon class="cursor-pointer" ngbTooltip="Any upload will be uploaded to the web server, but NOT published automatically to the PowerBI Service" container="body" [icon]="['fas', 'info-circle']"></fa-icon></label> 
                        <div class="col-sm-9">
                            <table>
                                <tbody>
                                    <tr>
                                        <td *ngIf="file">
                                            <span *ngIf="file">{{file.data.name}}</span><br/>
                                            <span *ngIf="file">{{file.data.size / 1024 / 1024 | number:'1.2-2'}} MB</span><br/>
                                            <div class="progress mb-2">
                                                <div class="progress-bar" role="progressbar" style="width: {{file.progress}}%;" aria-valuemin="0" aria-valuemax="100">{{file.progress}}%</div>
                                            </div>
                                            <!-- <progress *ngIf="file" [value]="file.progress"></progress>  -->
                                        </td>
                                        <td *ngIf="finishingUpload"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>&nbsp;Finalising Upload</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>
                                            <button class="btn btn-info" name="btnBrowse" (click)="browseClick()" [disabled]="publishing"><fa-icon [icon]="['fas', 'folder']"></fa-icon>  Browse</button>
                                            <button class="ml-2 btn btn-primary" (click)="onClick()" [disabled]="!file || uploading || publishing"><fa-icon [icon]="['fas', 'upload']"></fa-icon>  Upload</button>
                                            <!-- <button class="ml-2 btn btn-info" [disabled]="!basicInfo.isFileExist || !councils || publishing" (click)="publishToAllCouncils()">Publish to PowerBI for All Councils</button> -->
                                        </td>
                                        <!-- <td *ngIf="publishing"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>&nbsp;Publishing to PowerBI for all Councils</td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span><em>Any upload will be published to the PowerBI Service, and will automatically be generated for each Council</em></span> -->
                            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="false" accept=".pbix" style="display:none;" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label col-sm-3 text-md-right">Actions for All Councils <fa-icon ngbTooltip="Actions to apply to all councils, including the Global Admin" container="body" [icon]="['fas', 'info-circle']"></fa-icon></label> 
                        <div class="col-sm-9">
                            <button class="btn btn-info" [disabled]="!basicInfo.isFileExist || !councils || publishing" (click)="publishToAllCouncils()"><fa-icon [icon]="['fas', 'cloud-upload-alt']"></fa-icon>  Publish All To PowerBI</button>
                            <button class="ml-2 btn btn-info" [disabled]="!basicInfo.isFileExist || !councils || publishing" (click)="refreshAllCouncils()"><fa-icon [icon]="['fas', 'redo']"></fa-icon>  Refresh All Datasets</button>
                            <span class="ml-2" *ngIf="publishing"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>&nbsp;Publishing to PowerBI for all Councils</span>
                            <span class="ml-2" *ngIf="refreshingAll"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>&nbsp;Refreshing PowerBI datasets for all Councils</span>
                        </div>
                    </div>


                    <br/>
                    <div class="form-group row">
                        <h5 class="col-form-heading col-sm-12"> Council Reports and Datasets</h5> 
                    </div>
                    <div class="form-group row">
                        <div class="text-center loading-container" *ngIf="loading">
                            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
                            <span class="ml-2"><em>This may take several minutes to load</em></span>
                        </div>
                        <div class="table-responsive" *ngIf='!loading'>

                                <table class="table table-hover table-striped table-sm">
                                    <thead>
                                        <tr class="active">
                                            <th>Council name</th>
                                            <th>Report Name</th>
                                            <th>Dataset Name</th>
                                            <th class="text-center">Dataset Refreshable</th>
                                            <th class="text-center">Refresh Schedule</th>
                                            <th>Last Refresh
                                                <fa-icon class="cursor-pointer" ngbTooltip="Limited to 8 refreshes a day per council" container="body" [icon]="['fas', 'info-circle']"></fa-icon>
                                            </th>
                                            <th class="text-center">
                                                Ownership
                                                <fa-icon class="cursor-pointer" ngbTooltip="Ownership of reports and datasets is required for maintaining access and control of PowerBI reports, if a dataset or report is not owned, then select the Take Ownership button" container="body" [icon]="['fas', 'info-circle']"></fa-icon>
                                            </th>
                                            <!-- <th>Last Refresh</th> -->
                                            <th style="min-width:150px"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="pbi-client-table small ui-sortable" [ngClass]="{'disabled-table': disableTable}">
                                        <tr *ngIf="councils.length===0">
                                            <td colspan="3">No Councils to display</td>
                                        </tr>
                                        <tr *ngFor="let council of councils">
                                            <td [ngClass]="{'font-weight-bold': council.id === 0 && council.name === 'Global Admin'}">{{council.name}}</td>
                                            <td colspan="2" *ngIf="!council.reportId"><em>This council has not been configured with a report</em></td>
                                            <td *ngIf="council.reportId"><a target="_blank" href="{{council.report.url}}">{{council.report.name}}</a></td>
                                            <td *ngIf="council.reportId"><a target="_blank" href="{{council.dataset.url}}">{{council.dataset.name}}</a></td>
                                            <td class="text-center">
                                                <fa-icon [hidden]="!council.dataset.isRefreshable || !council.reportId" [icon]="['fas', 'check']"></fa-icon>
                                                <fa-icon [hidden]="council.dataset.isRefreshable || !council.reportId" [icon]="['fas', 'times']"></fa-icon>
                                            </td>
                                            <td *ngIf="council.reportId" class="cursor-pointer" [ngbTooltip]="tableTipRefreshSchedule" container="body" class="text-center">
                                                <fa-icon [hidden]="!council.dataset.refreshSchedule.enabled || !council.reportId" [icon]="['fas', 'check']"></fa-icon>
                                                <fa-icon [hidden]="council.dataset.refreshSchedule.enabled || !council.reportId" [icon]="['fas', 'times']"></fa-icon> 
                                                &nbsp;<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                                            </td>
                                            <td *ngIf="!council.reportId"></td>
                                            <td *ngIf="council.reportId">
                                                <span class="cursor-pointer" [ngbTooltip]="tableTipRefreshHistory" container="body" [hidden]="!council.dataset.lastRefreshStatus">{{council.dataset.lastRefreshTime | date:'medium'}}<br/>({{council.dataset.lastRefreshStatus}})</span>
                                                &nbsp;<fa-icon [hidden]="!council.dataset.lastRefreshStatus" [icon]="['fas', 'info-circle']"></fa-icon>
                                            </td>
                                            <td *ngIf="!council.reportId"></td>
                                            <td *ngIf="council.reportId" class="text-center">
                                                <fa-icon class="cursor-pointer" ngbTooltip="Report Ownership" container="body" [hidden]="!council.report.isOwnedByMe || !council.reportId" [icon]="['fas', 'check']"></fa-icon>
                                                <fa-icon class="cursor-pointer" ngbTooltip="Report Ownership" container="body" [hidden]="council.report.isOwnedByMe || !council.reportId" [icon]="['fas', 'times']"></fa-icon>
                                                &nbsp;<fa-icon class="cursor-pointer" ngbTooltip="Dataset Ownership" container="body" [hidden]="!council.dataset.configuredBy || !council.reportId" [icon]="['fas', 'check']"></fa-icon>
                                                <fa-icon class="cursor-pointer" ngbTooltip="Dataset Ownership" container="body" [hidden]="council.dataset.configuredBy || !council.reportId" [icon]="['fas', 'times']"></fa-icon>
                                                &nbsp;<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
                                            </td>
                                            <td *ngIf="!council.reportId"></td>
                                            <td>
                                                <fieldset [disabled]="disableTable">
                                                    <div class="btn-group row-options btn-group-sm">
                                                        <a *ngIf="council.reportId" class="btn btn-ligth cursor-pointer" ngbTooltip="Manually Refresh Dataset, Note this is limited to 8 refreshes per day" container="body" (click)="refreshCouncilDataset(council)">
                                                            <fa-icon [icon]="['fas', 'redo-alt']"></fa-icon>
                                                        </a>
                                                        <a *ngIf="council.reportId" class="btn btn-ligth btn-xs btn-red-hover cursor-pointer" ngbTooltip="Enable/Disable Refresh Schedule" container="body" (click)="toggleCouncilRefreshSchedule(council)">
                                                            <fa-icon [icon]="['fas', 'clock']"></fa-icon>
                                                        </a>
                                                        <a *ngIf="council.reportId" class="btn btn-ligth btn-xs btn-red-hover cursor-pointer" ngbTooltip="Take Ownership" container="body" (click)="takeOwnershipCouncilDataset(council)">
                                                            <fa-icon [icon]="['fas', 'wrench']"></fa-icon>
                                                        </a>
                                                        <a *ngIf="council.reportId" class="btn btn-ligth btn-xs btn-red-hover cursor-pointer" ngbTooltip="Regenerate Report" container="body" (click)="publishToCouncil(council)">
                                                            <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
                                                        </a>
                                                        <a *ngIf="!council.reportId" class="btn btn-ligth btn-xs btn-red-hover cursor-pointer" ngbTooltip="Generate Report" container="body" (click)="publishToCouncil(council)">
                                                            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                                        </a>
                                                        <a *ngIf="council.reportId" class="btn btn-ligth btn-xs btn-red-hover cursor-pointer" placement="left" ngbTooltip="Generate New Security Token" container="body" (click)="manualUpdateToken(council)">
                                                            <fa-icon [icon]="['fas', 'key']"></fa-icon>
                                                        </a>
                                                    </div>
                                                </fieldset>
                                            </td>
                                            <ng-template #tableTipRefreshSchedule>
                                                <dl>
                                                    <dt>Enabled:</dt>
                                                    <dd>{{ council.dataset.refreshSchedule.enabled }}</dd>
                                                    <dt>Days:</dt>
                                                    <dd>{{ council.dataset.refreshSchedule.days | json }}</dd>
                                                    <dt>Times:</dt>
                                                    <dd>{{ council.dataset.refreshSchedule.times | json }}</dd>
                                                    <dt>Local Time Zone:</dt>
                                                    <dd>{{ council.dataset.refreshSchedule.localTimeZoneId }}</dd>
                                                    <dt>Notify Option:</dt>
                                                    <dd>{{ council.dataset.refreshSchedule.notifyOption }}</dd>
                                                </dl>
                                            </ng-template>
                                            <ng-template #tableTipRefreshHistory>
                                                <dl>
                                                    <dt>Status:</dt>
                                                    <dd>{{ council.dataset.history.status }}</dd>
                                                    <dt>Start Time:</dt>
                                                    <dd [hidden]="!council.dataset.history.startTime">{{ council.dataset.history.startTime | date:'medium' }}</dd>
                                                    <dt>End Time:</dt>
                                                    <dd [hidden]="!council.dataset.history.endTime">{{ council.dataset.history.endTime | date:'medium' }}</dd>
                                                    <dt>Refresh Type:</dt>
                                                    <dd>{{ council.dataset.history.refreshType }}</dd>
                                                    <dt [hidden]="!council.dataset.history.serviceExceptionJson">Exception:</dt>
                                                    <dd>{{ council.dataset.history.serviceExceptionJson | json }}</dd>
                                                </dl>
                                            </ng-template>
                                        </tr> 
                                    </tbody>
                                </table>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>

</div>
      