import { CouncilUser } from "./CouncilUsers";

export class User {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  contactNumber: string;
  password: string;
  salt: string;
  token!:string;
  inviteToken: string;
  resetToken: string;
  councilUsers: CouncilUser[]
  selectedCouncilUser : CouncilUser;
  created: Date;
  updated: Date;
  deleted: Date;
}
