<!-- 
<main class="container-fluid min-vh-100">
  <div class="row">
  </div>
</main> -->
<main class="container-fluid pt-0">
  <div class="row">
    <div class="col-md-12 px-3 ">
      <!-- <router-outlet></router-outlet> -->
      <app-council-admin-dashboard *ngIf="highestCurrentUserRole==='councilAdmin'" ></app-council-admin-dashboard>
      <app-global-admin-dashbord *ngIf="highestCurrentUserRole==='globalAdmin'"></app-global-admin-dashbord>
      <app-truck-driver-dashboard *ngIf="highestCurrentUserRole==='truckDriver'"></app-truck-driver-dashboard>
      <app-house-owner-dashboard *ngIf="highestCurrentUserRole==='houseOwner'"></app-house-owner-dashboard>
    </div>
  </div>
</main>

