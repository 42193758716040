import { getAllLifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services';
import { Council } from 'src/app/shared/models/Council';

@Injectable({
  providedIn: 'root'
})
export class CouncilService {

  constructor(private apiService:ApiService) { }

  getAll():Observable<Council[]>{
    return this.apiService.get('/api/councils');
  }

  getCouncil(id:string):Observable<Council>{
    return this.apiService.get(`/api/councils/${id}`)
  }

  getArchivedCouncils():Observable<Council[]>{
    return this.apiService.get('/api/councils/archived');
  }
  getCouncilStats(): Observable<any>{
    return this.apiService.get('/api/councils/stats');
  }

  creatCouncil(council : Council): Observable<Council>{
    return this.apiService.post('/api/councils',council);
  }
  updateCouncil(council : Council): Observable<Council>{
    return this.apiService.put(`/api/councils/${council.id}`,council);
  }

  archiveCouncil(id:string):Observable<any>{
    return this.apiService.post(`/api/councils/archive/${id}`);
  }
  
  restoreCouncil(id:string):Observable<any>{
    return this.apiService.post(`/api/councils/restore/${id}`);
  }

  deleteCouncil(id:string):Observable<any>{
    return this.apiService.delete(`/api/councils/${id}`);
  } 
}

