import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { forEach } from 'lodash';
import { AuthenticationService } from 'src/app/core/services';
import { TokenType } from 'src/app/shared/models/enums';


@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styles: [
  ]
})
export class CreatePasswordComponent implements OnInit {

  @Input() formType :string;
  @Output() formSubmitted = new EventEmitter<{password:string,token:string}>()
  @Output() formCancelled = new EventEmitter();
 

  private notifier: NotifierService;
  token:string;
  createPasswordForm: FormGroup;
  isSubmitting = false;
  errors = null;
  validationMessages = {
    'password': {

      'required': 'password is required',
      'minlength': 'password min length 5',
      'maxlength': 'password max length 15',
      'mismatch': 'password mismatch'
    },
    'confirmPassword': {
      'required': 'confirm password is required',
      'minlength': 'confirm password min length 5',
      'maxlength': 'confirm password max length 15',
      'mismatch': 'confirm password mismatch'
    }

  };

  formErrors = {
    'password': null,
    'confirmPassword': null,
    'passwordMismatch': null
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    notifierService: NotifierService,
    private authService: AuthenticationService,
    public formBuilder: FormBuilder) {
    this.notifier = notifierService;
  }


  ngOnInit() {
    this.createPasswordForm = this.formBuilder.group({
      'password': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)]
      ],
      'confirmPassword': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)]
      ]

    }, { validators: this.confirmPasswordMatchValidation });
    this.createPasswordForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.createPasswordForm);
    })
    // ,{validators:this.password.bind(this)});
    this.route.params.subscribe(
      (params) => {
        this.token = params.token;
        this.verifyToken(params.token, this.formType);
      },
      (err) => {
        this.notifier.notify('error', err.message)
      }
    )
  }

  logValidationErrors(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl && abstractControl.dirty && abstractControl.invalid) {
        const messages = this.validationMessages[key]
        this.formErrors[key] = '';
        forEach(abstractControl.errors, (value, errorType) => {
          this.formErrors[key] += messages[errorType] + '\n';
        })
      } else {
        this.formErrors[key] = '';
      }
      const password = group.get('password');
      const confirmPassword = group.get('confirmPassword');

      if (password.dirty && confirmPassword.dirty && group.errors) {
        this.formErrors['passwordMismatch'] = group.errors.passwordNotMatch;
      } else {
        this.formErrors['passwordMismatch'] = null
      }
    })
  }

  verifyToken(token: string, tokenType: string) {
    this.authService.verifyToken(token, tokenType).subscribe((res) => {
      if (!res['data'].isTokenValid) {
        this.notifier.notify('error', 'Invalid token or token has already being used') 
        this.router.navigate(['/auth/signin']);
      }
    }, (err) => {
      this.router.navigate(['/auth/signin']);
      this.notifier.notify('error', err.message)
    })

  }

  confirmPasswordMatchValidation(formGroup: FormGroup): { [key: string]: any } | null {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: 'Password not match' };
  }

  submitForm() {
    const password = this.createPasswordForm.value['password']
    this.formSubmitted.emit({password,token:this.token});
  }

  cancelForm(){
    this.formCancelled.emit();
  }
}
