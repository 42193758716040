
      <section id="report">
        <div class="text-center loading-container w-100 mb-2">
          <fa-icon *ngIf="loading3" [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
        </div>

        <div [hidden]="loading3" style="width:100%;">
          <div *ngIf="loading" class="watermark" [ngStyle]="{'opacity': watermarkOpacity}"><img src="./assets/images/Dark-Green.png"></div>
          <div class="pbi-report" [hidden]="!rendered">
            
            <div [hidden]="loading" class="pbi-sidebar">
              <a class="btn btn-primary btn-sm" (click)="fullscreen()">
                <fa-icon [icon]="['fas', 'expand-arrows-alt']" size="lg" [fixedWidth]="true"></fa-icon>
              </a>
              <a class="btn btn-primary btn-sm" (click)="reload()">
                <fa-icon [icon]="['fas', 'sync-alt']" size="lg" [fixedWidth]="true"></fa-icon>
              </a>
            </div> 
            <div [hidden]="loading" class="pbi-frame"  #reportContainer></div>
          </div>
        </div>
        
      </section>
