import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsDataService } from '../settings-data.service';
import { Council } from 'src/app/shared/models/Council';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';  
import { catchError, map } from 'rxjs/operators';  
import { NotifierService } from 'angular-notifier';
import { UserRole } from 'src/app/shared/models/enums';
import { PowerBiService } from '../../shared/power-bi/services/power-bi.service';
import { UploadService } from  '../../shared/upload/upload.service';
import { AuthenticationService } from 'src/app/core/services';
import { CouncilUser } from 'src/app/shared/models/CouncilUsers';


@Component({
  selector: 'app-settings-upload',
  templateUrl: './settings-upload.component.html',
  styles: []
})
export class SettingsUploadComponent implements OnInit {
  private notifier : NotifierService;
  private selectedCouncilUser: CouncilUser;
  public highestCurrentUserRole: UserRole;
  uploading:boolean = false;
  loading:boolean = true;
  finishingUpload:boolean = false;
  publishing:boolean = false;
  refreshingAll:boolean = false;
  disableTable:boolean = false;
  basicInfo: any = {};
  councils: any[];
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
  file  = null;  
  // results: any;

  constructor(
    private uploadService: UploadService,
    private powerBiService: PowerBiService,
    private authService: AuthenticationService,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService;
     }
  // constructor(private dataService: SettingsDataService, private http: HttpClient) { }

  ngOnInit(): void {

    this.selectedCouncilUser = this.authService.currentUserValue.selectedCouncilUser;
    this.highestCurrentUserRole = this.selectedCouncilUser.roles[0]
    this.getBasicPowerBiInfo()
    this.fetchCouncils();
  }

  getBasicPowerBiInfo(){
    this.powerBiService.getBasicPowerBiInfo().subscribe(
      (res) => {
        this.basicInfo = res;
      },
      (err) => {
        this.notifier.notify('error', err.message);
        
      }
    )
  }

  fetchCouncils(){
    this.loading = true;
    this.powerBiService.getCouncils().subscribe(
      (res) => {
        this.councils = res['data']['allCouncils'];
      },
      (err) => {
        this.notifier.notify('error', err.message);
        this.loading = false;
      },
      //final block
      () => {
        this.loading = false;
      }
    )
  }

  async publishToAllCouncils(){
    this.publishing = true;
    for await (let council of this.councils) {
      await this.publishToCouncil(council,true)
    }
    this.publishing = false;
    this.fetchCouncils();
  }

  async publishToCouncil(council: Council,ignoreRefresh:boolean = false){
    return new Promise((resolve , reject) => {
      this.disableTable = true;
      this.powerBiService.publishToCouncil(council.id).subscribe(
        (res) => {
          this.notifier.notify('success', res.message);
          if (!ignoreRefresh) {
            this.fetchCouncils();
          }
          resolve(res);
        },
        (err) => {
          this.disableTable = false;
          this.notifier.notify('error', err);
          reject(err);
        },
        () => {
          this.disableTable = false;
        }
      )
    });
  }

  async refreshAllCouncils(){
    this.refreshingAll = true;
    for await (let council of this.councils) {
      await this.refreshCouncilDataset(council,true)
    }
    this.refreshingAll = false;
    this.fetchCouncils();
  }

  refreshCouncilDataset(council: Council,ignoreRefresh:boolean = false){
    return new Promise((resolve , reject) => {
      this.disableTable = true;
      this.powerBiService.refreshCouncilDataset(council.id).subscribe(
        (res) => {
          this.notifier.notify('success', res.message);
          if (!ignoreRefresh) {
            this.fetchCouncils();
          }
          resolve(res);
        },
        (err) => {
          this.disableTable = false;
          this.notifier.notify('error', err);
          reject(err);
        },
        //final block
        () => {
          this.disableTable = false;
        }
      )
    });
  }

  toggleCouncilRefreshSchedule(council: Council){
    this.disableTable = true;
    this.powerBiService.toggleCouncilRefreshSchedule(council.id).subscribe(
      (res) => {
        this.notifier.notify('success', res.message);
        this.fetchCouncils();
      },
      (err) => {
        this.disableTable = false;
        this.notifier.notify('error', err);
      },
      //final block
      () => {
        this.disableTable = false;
      }
    )
  }

  takeOwnershipCouncilDataset(council: Council){
    this.disableTable = true;
    this.powerBiService.takeOwnershipCouncilDataset(council.id).subscribe(
      (res) => {
        this.notifier.notify('success', res.message);
        this.fetchCouncils();
      },
      (err) => {
        this.disableTable = false;
        this.notifier.notify('error', err);
      },
      //final block
      () => {
        this.disableTable = false;
      }
    )
  }

  manualUpdateToken(council: Council){
    this.disableTable = true;
    this.powerBiService.manualUpdateToken(council.id).subscribe(
      (res) => {
        this.notifier.notify('success', res.message);
      },
      (err) => {
        this.disableTable = false;
        this.notifier.notify('error', err);
      },
      () => {
        this.disableTable = false;
      }
    )
  }

  uploadFile(file) {  
    this.uploading = true;
    const formData = new FormData();  
    formData.append('file', file.data);  
    file.inProgress = true;  
    this.uploadService.postReport(formData)
    .pipe(  
      map(event => {  
        switch (event.type) {  
          case HttpEventType.UploadProgress:  
            file.progress = Math.round(event.loaded * 100 / event.total);  
            if (file.progress === 100) {
              this.finishingUpload = true;
            }
            break;  
          case HttpEventType.Response:  
            return event;  
        }  
      }),  
      catchError((error: HttpErrorResponse) => {  
        file.inProgress = false;  
        this.uploading = false;
        this.finishingUpload = false;
        return of(`${file.data.name} upload failed.`);  
      }))
      .subscribe((event: any) => {
        if (typeof (event) === 'object') {  
          this.notifier.notify('success', event.body.message)
          this.finishingUpload = false;
          // this.uploading = false;
          console.log(event.body);  
          // this.fetchCouncils();
          this.getBasicPowerBiInfo();
        }  
      });  
  }


  private uploadFiles() {  
    this.fileUpload.nativeElement.value = '';  
    // this.files.forEach(file => {  
      this.uploadFile(this.file);  
    // });  
  }

  onClick() {  
    this.uploadFiles();  
  }

  browseClick() {
    this.uploading = false;
    this.file = null;
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {  
      this.file = { data: fileUpload.files[0], inProgress: false, progress: 0};
    };
    fileUpload.click()
  }

}
