import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services';
import { Observable } from 'rxjs';
import { User } from '../../shared/models';
import { CouncilUser } from 'src/app/shared/models/CouncilUsers';
import { UserRole } from 'src/app/shared/models/enums';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService:ApiService) { 
    
  }

  getAll():Observable<User[]>{
    return this.apiService.get('/api/users')
  }

  getCouncilUsers(userRole:UserRole):Observable<CouncilUser>{
    return this.apiService.get('/api/council-users',{userRole})
  }

  getUserById(id:string):Observable<User>{
    return this.apiService.get(`/api/users/${id}`)
  }
  getArchivedUsers():Observable<User>{
    return this.apiService.get('/api/users/archived');
  }

  createUser(user:User):Observable<User>{
    return this.apiService.post('/api/users',{...user})
  }

  updateUser(user:User):Observable<User>{
    return this.apiService.put(`/api/users/${user.id}`,{...user})
  }

  archiveUser(userId:string): Observable<any>{
    return this.apiService.post(`/api/users/archive/${userId}`);
  }

  restoreUser(userId:string): Observable<any>{
    return this.apiService.post(`/api/users/restore/${userId}`);
  }

  deleteUser(userId:string): Observable<any>{
    return this.apiService.delete(`/api/users/${userId}`);
  }

  getArchivedCouncilUsers():Observable<User>{
    return this.apiService.get('/api/council-users/archived');
  }

  archiveCouncilUser(councilUserId:string): Observable<any>{
    return this.apiService.post(`/api/council-users/archive/${councilUserId}`);
  }

  restoreCouncilUser(councilUserId:string): Observable<any>{
    return this.apiService.post(`/api/council-users/restore/${councilUserId}`);
  }

  deleteCouncilUser(councilUserId:string): Observable<any>{
    return this.apiService.delete(`/api/council-users/${councilUserId}`);
  }

  resendEmailInvitation(userId:string):Observable<any>{
    return this.apiService.get(`/api/users/${userId}/re-send-invite`)
  }

  setCurrentCouncilUser(councilUser:CouncilUser):Observable<User>{
    return this.apiService.post('/api/users/set-selected-council-user',{councilUser});
  }
}
