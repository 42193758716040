import { getAllLifecycleHooks } from '@angular/compiler/src/lifecycle_reflector';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services';
// import { Council } from 'src/app/shared/models/Council';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {

  constructor(private apiService:ApiService) { }

  getBasicPowerBiInfo():Observable<any>{
    return this.apiService.get('/api/dashboard/get-basic-info');
  }

  getCouncils():Observable<any>{
    return this.apiService.getDelay('/api/dashboard/get-councils');
  }

  publishToCouncil(id:string):Observable<any>{
    return this.apiService.post(`/api/dashboard/report-publish/${id}`);
  }
 
  refreshCouncilDataset(id:string):Observable<any>{
    return this.apiService.get(`/api/dashboard/report-refresh/${id}`);
  }

  toggleCouncilRefreshSchedule(id:string):Observable<any>{
    return this.apiService.patch(`/api/dashboard/report-schedule/${id}`);
  }

  takeOwnershipCouncilDataset(id:string):Observable<any>{
    return this.apiService.post(`/api/dashboard/report-own/${id}`);
  }

  manualUpdateToken(id:string):Observable<any>{
    return this.apiService.post(`/api/dashboard/token-update/${id}`);
  }

}

