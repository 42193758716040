import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterModule, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import * as d3 from 'd3';
import { select } from 'd3';
import { filter, distinctUntilChanged } from 'rxjs/operators';

export interface IBreadcrumb {
  label: string,
  url: string
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styles: []
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[];
  // loading=false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);

    //

    //   this.router.events.subscribe((event)=>{
    //    switch(true){
    //      case event instanceof NavigationStart:{
    //        this.loading=true;
    //        console.log('Test',this.loading);
    //        break;
    //      }
    //      case event instanceof NavigationEnd:
    //      case event instanceof NavigationCancel:
    //      case event instanceof NavigationError: {
    //        this.loading = false;
    //        break;
    //      }
    //      default :{
    //        break;
    //      }
    //    }
    //  })
    //  //
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => { return event instanceof NavigationEnd }),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    })


  }
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {

    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : "home";
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : "";
    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = (route.routeConfig && route.routeConfig.data) ? route.routeConfig.data.breadcrumb : route.snapshot.params[paramName];
    }
    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadcrumb = {
      label: label,
      url: nextUrl,
    };
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }


}
