<div class="text-center loading-container">
    <fa-icon *ngIf="loading" [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
</div>
<div class="row p-3 " *ngIf="!loading">
    <div class="col-sm-12 ">
        <ul class="list-group " *ngFor="let item of data | keyvalue:returnZero">
            <li  class="list-group-item bg-dark text-white pl-1" *ngIf="issObject(item.value)">
               <strong>{{item.key | camelToNormalCase |titlecase}}</strong>
            </li>
            
            <!-- <div *ngIf="!issObject(item.value)"> -->
                <ul class="float-left" *ngFor="let subItem of item.value | keyvalue:returnZero">
                    <li class="list-group-item border-0 p-1" >
                        {{subItem.key | camelToNormalCase}}
                        <span class="badge badge-success badge-pill float-right">{{subItem.value}}</span>
                    </li>
                </ul>
        </ul>
    </div>
</div>