import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/services';
import { User } from './shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['']
})
export class AppComponent implements OnInit {

  currentUser: User;

  constructor(private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit() {
    this.authenticationService.populate();

    this.authenticationService.currentUser.subscribe((user) =>{this.currentUser = user} );
    
  }

}
