import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { AuthGuard } from './core/services';
// import { Role } from './shared/models/enums';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ForbiddenComponent } from './core/forbidden/forbidden.component';
import { AuthComponent } from './auth/auth.component';
import { SigninComponent } from './auth/signin/signin.component';
import { UserRole } from './shared/models/enums';
import { InviteComponent } from './auth/invite/invite.component';
import { BindataComponent } from './bindata/bindata.component';
import { User } from './shared/models';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      permission: [UserRole.GlobalAdmin, UserRole.CouncilAdmin,UserRole.TruckDriver,UserRole.HouseOwner],
      breadcrumb: '',
      title: 'Home',
      position: 0.0,
      icon: 'home'
    }
  },
  {
    path: 'auth', component: AuthComponent, children: [
      { path: 'signin', component: SigninComponent },
      { path: 'invite/:token', component: InviteComponent },
      { path: 'reset/:token', component: ForgetPasswordComponent }

    ]
  },
  //lazy loading user module
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { permission: [UserRole.GlobalAdmin,UserRole.CouncilAdmin], breadcrumb: 'users', title: 'Users' },
    loadChildren: () => import('./users/users.module').then((module) => { return module.UsersModule })
  },
  {
    path: 'councils',    
    data: { permission: [UserRole.GlobalAdmin,UserRole.CouncilAdmin], breadcrumb: 'councils', title: 'Councils', position: 1.0, icon: 'archway' },
    loadChildren: () => import('./council/council.module').then((module) => { return module.CouncilModule })
  },
  {
    path: 'sensors',
    canActivate: [AuthGuard],
    data: { permission: [UserRole.GlobalAdmin, UserRole.CouncilAdmin], breadcrumb: 'sensors', title: 'Sensors', position: 2.0, icon: 'broadcast-tower' },
    loadChildren: () => import('./sensor/sensor.module').then((module) => { return module.SensorModule })
  },
  {
    path: 'locations',
    canActivate: [AuthGuard],
    data: { permission: [UserRole.GlobalAdmin, UserRole.CouncilAdmin], breadcrumb: 'location', title: 'Locations', position: 3.0, icon: 'location-arrow' },
    loadChildren: () => import('./location/location.module').then((module) => { return module.LocationModule })
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    data: { permission: [UserRole.GlobalAdmin], breadcrumb: 'settings', title: 'Settings' },
    loadChildren: () => import('./settings/settings.module').then((module) => { return module.SettingsModule })
  },

  // {
  //   path: 'bin-data',
  //   canActivate: [AuthGuard],
  //   data: { permission: [UserRole.GlobalAdmin, UserRole.CouncilAdmin], breadcrumb: 'bin-data', title: 'Bin Data', position: 4.0, icon: 'database' },
  //   component : BindataComponent
  //   // loadChildren: () => import('./bindata/bindata.module').then((module) => { return module.BindataModule })
  // },


  // // lazy loading timesheets
  // {
  //   path: 'timsheets',
  //   canActivate: [AuthGuard],
  //   data: {
  //     permission: [Role.admin, Role.user],
  //     breadcrumb: 'timesheets',
  //     title: 'Timesheets',
  //     position: 1,
  //     icon: 'clock'
  //   },
  //   loadChildren: () => import('./timesheets/timesheets.module').then((module) => { return module.TimesheetsModule })
  // },
  //lazy load clients
  // {
  //   path: 'clients',
  //   canActivate: [AuthGuard],
  //   data: {
  //     permission: [Role.admin],
  //     breadcrumb: 'clients',
  //     title: 'Clients',
  //     position: 3.1,
  //     icon: 'building'
  //   },
  //   loadChildren: () => import('./clients/clients.module').then((module) => { return module.ClientsModule })
  // },
  { path: 'not-found', component: NotFoundComponent, data: { breadcrumb: 'not found' } },
  { path: 'forbidden', component: ForbiddenComponent, data: { breadcrumb: 'forbidden' } },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
