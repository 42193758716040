<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page">Configuration</li>
  </ol>
</nav>

<div class="card-deck">
  <a class="card" [routerLink]="['positions']">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <fa-icon [icon]="['fas', 'sitemap']" [size]="'3x'" ></fa-icon>
        </div>
        <div class="col-8">
          <h2 class="card-title">Position Types</h2>
        </div>
      </div>
    </div>
  </a>
  <a class="card" [routerLink]="['departments']">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <fa-icon [icon]="['fas', 'building']" [size]="'3x'"></fa-icon>
        </div>
        <div class="col-8">
          <h3 class="card-title">Departments</h3>
        </div>
      </div>
    </div>
  </a>
  <a class="card" [routerLink]="['competencies']">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <fa-icon [icon]="['fas', 'award']" [size]="'3x'"></fa-icon>
        </div>
        <div class="col-8">
          <h3 class="card-title">Competencies</h3>
        </div>
      </div>
    </div>
  </a>
  <a class="card" [routerLink]="['licences']">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <fa-icon [icon]="['fas', 'id-card']" [size]="'3x'"></fa-icon>
        </div>
        <div class="col-8">
          <h3 class="card-title">Licences</h3>
        </div>
      </div>
    </div>
  </a>

</div>