<!-- <main class="container-fluid">
  <div class="row">
    <div class="col-md-12 p-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</main> -->

<main class="container-fluid pt-0">
  <div class="row">
    <div class="col-md-12 px-3 ">
      <!-- <router-outlet></router-outlet> -->
      <app-settings-upload *ngIf="highestCurrentUserRole==='globalAdmin'"></app-settings-upload>
    </div>
  </div>
</main>