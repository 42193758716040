import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CouncilAdminDshboardComponent } from './council-admin-dashboard/council-admin-dashboard.component';
import { GlobalAdminDashbordComponent } from './global-admin-dashbord/global-admin-dashbord.component';
import { HouseOwnerDashboardComponent } from './house-owner-dashboard/house-owner-dashboard.component';
import { TruckDriverDashboardComponent } from './truck-driver-dashboard/truck-driver-dashboard.component';



@NgModule({
  declarations: [CouncilAdminDshboardComponent, GlobalAdminDashbordComponent, HouseOwnerDashboardComponent, TruckDriverDashboardComponent],
  imports: [
    SharedModule
  ],
  exports:[
    CouncilAdminDshboardComponent,
    GlobalAdminDashbordComponent,
    TruckDriverDashboardComponent,
    HouseOwnerDashboardComponent
  ]
})
export class DashboardModule { }
