<div class="card-group">
  <div class="card">
    <div class="card-body pl-0 pr-2 pb-0">
      <h6 class="card-title text-center"> Temperature Quantile</h6>
      <div *ngIf=loading class="text-center loading-container">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
      </div>
      <div [hidden]="loading" id="BPr1c1" #bplotContainer></div>

    </div>
  </div>
  <div class="card">
    <div class="card-body pl-0 pr-2 pb-0">
      <h6 class="card-title text-center"> Bin Level Quantile</h6>
      <div *ngIf=loading class="text-center loading-container">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
      </div>
      <div [hidden]="loading" id="BPr1c2"></div>

    </div>
  </div>
  <div class="card">
    <div class="card-body pl-0 pr-2 pb-0">
      <h6 class="card-title text-center"> Gas Reading Quantile</h6>
      <div *ngIf=loading class="text-center loading-container">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
      </div>
      <div [hidden]="loading" id="BPr1c3"></div>

    </div>
  </div>
</div>