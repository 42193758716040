<div class="col-md-6 offset-md-3 col-xs-12 bg-white p-3 rounded">
    <div class="col-md-6 offset-md-3 col-xs-12 text-center ">
        <img src="../../../assets/images/Binsense_logo.png" alt="..." class=" border-0">
    </div>
    <div class="col-md-8 offset-md-2 ">
        <h3 class="text-xs-center mb-4">Create Password</h3>
        <form [formGroup]="createPasswordForm" (ngSubmit)="submitForm()">
            <fieldset>
                <div class="form-group">
                    <input formControlName="password" placeholder="Password" class="form-control form-control-lg"
                        [ngClass]="{'is-invalid': formErrors.password}" type="password" />
                    <span class="help-block" *ngIf="formErrors.password">
                        {{formErrors.password}}
                    </span>
                </div>

                <div class="form-group">
                    <input formControlName="confirmPassword" placeholder="Confirm Password"
                        class="form-control form-control-lg"
                        [ngClass]="{'is-invalid': (formErrors.confirmPassword || formErrors.passwordMismatch)}"
                        type="password" />
                    <span class="help-block" *ngIf="formErrors.confirmPassword || formErrors.passwordMismatch">
                        {{formErrors.confirmPassword}}{{ formErrors.passwordMismatch}}
                    </span>
                </div>
                <!-- <div class="alert alert-danger" *ngIf="createPasswordForm.confirmPassword.invalid" >
              Name is required.
          </div> -->
                <button class="btn btn-lg btn-primary float-right ml-2" [disabled]="!createPasswordForm.valid"
                    type="submit">
                    Submit
                </button>
                <button class="btn btn-lg btn-secondary float-right ml-2" (click)="cancelForm()"
                    type="button">
                    Cancel
                </button>
            </fieldset>
        </form>
    </div>


</div>