<nav class="navbar navbar-expand-md navbar-dark fixed-top pb-2">
  <a class="navbar-brand" [routerLink]="['']">
    <img src="../../../assets/images/Binsense_logo.png" alt="Binsense" title="Binsense" height="36" />
  </a>
  <button class="navbar-toggler" type="button" (click)="navbarCollapsed = !navbarCollapsed"
    [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarCollapse">
    <ul class="navbar-nav mr-auto" *appShowAuthed="true">
      <li [ngClass]="(menuItem.path===null)?'nav-item dropdown':'nav-item'" ngbDropdown routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}" *ngFor="let menuItem of menuItems">
        <a class="nav-link" *ngIf="menuItem.path!==null" [routerLink]="[menuItem.path]">
          <fa-icon [icon]="['fas',menuItem.icon]" [size]="'lg'" [fixedWidth]="true" class="mr-1"></fa-icon>
          {{menuItem.title}}
        </a>
        <!-- sub menu item -->
        <a class="nav-link dropdown-toggle" *ngIf="menuItem.path===null" id="navbarDropdown11" role="button"
          data-toggle="dropdown" ngbDropdownToggle>
          <fa-icon [icon]="['fas',menuItem.icon]" [size]="'lg'" [fixedWidth]="true" class="mr-1"></fa-icon>
          {{menuItem.title}}
        </a>
        <div *ngIf="menuItem.path===null" class="dropdown-menu" aria-labelledby="navbarDropdown11" ngbDropdownMenu>
          <a class="dropdown-item" [routerLink]="[subItem.path]" *ngFor="let subItem of menuItem.subMenu">
            <fa-icon [icon]="['fas',subItem.icon]" [size]="'lg'" [fixedWidth]="true" class="mr-1"></fa-icon>
            {{subItem.title}}
          </a>
        </div>
        <!-- end of sub menu item -->
      </li>


    </ul>

    <ul class="ml-auto navbar-nav">
      <li class="nav-item" *appShowAuthed="false">
        <a class="nav-link" [routerLink]="['/auth/signin']">Login</a>
      </li>
      <li class="nav-item"  *ngIf="highestCurrentUserRole !== 'globalAdmin'" ngbDropdown placement="bottom-right">
        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink1" role="button" data-toggle="dropdown"
          ngbDropdownToggle>Selected Council : {{currentUserValue.selectedCouncilUser.council?.name}} </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1" ngbDropdownMenu>
          <a class="dropdown-item" [hidden]="councilUser.deleted" (click)="changeCouncil(councilUser)"
            *ngFor="let councilUser of  currentUserValue.councilUsers">
            <fa-icon [icon]="['fas', 'user']"></fa-icon> {{councilUser.council.name}}
          </a>
        </div>
      </li>
      <li class="nav-item dropdown" *appShowAuthed="true" ngbDropdown placement="bottom-right">
        <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
          ngbDropdownToggle>
          Logged in as {{ currentUser.userName }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
          <!-- <a class="dropdown-item disabled">Updated Profile</a>
            <a class="dropdown-item disabled">Change Password</a> -->
          <a class="dropdown-item" [routerLink]="['/users',this.currentUserValue.id]">
            <fa-icon [icon]="['fas', 'user']"></fa-icon> User profile
          </a>
          <a class="dropdown-item" *ngIf="highestCurrentUserRole === 'globalAdmin'||
             highestCurrentUserRole === 'councilAdmin' " [routerLink]="['/users']">
            <fa-icon [icon]="['fas', 'users']"></fa-icon> Users
          </a>

          <a class="dropdown-item" *ngIf="highestCurrentUserRole === 'globalAdmin'" [routerLink]="['/settings']">
            <fa-icon [icon]="['fas', 'cog']"></fa-icon> Settings
          </a>


          <!-- <a class="dropdown-item" [routerLink]="['/xero']">
            Xero
          </a> -->
          <!-- <a class="dropdown-item" [routerLink]="['/emailschedule']">
            <fa-icon [icon]="['fas', 'envelope']"></fa-icon> Email Schedule
          </a> -->
          <a class="dropdown-item" (click)="logout()">
            <fa-icon [icon]="['fas','sign-out-alt']"></fa-icon> Logout
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<app-breadcrumb *appShowAuthed="true"></app-breadcrumb>