<div class="card-group">
    <power-bi style="width:100%;"></power-bi>
</div>
<div class="card-group vh-25">
    <div class="card">
        <div class="card-body pl-0 pr-2 pb-0 ">
            <h5 class="card-title text-center">Application Statistics </h5>
            <app-council-stats></app-council-stats>
        </div>
    </div>
    <div class="card">
        <div class="card-body pl-0 pr-2 pb-0 mt-auto">

            <h5 class="card-title text-center">Bin data summary </h5>  
            <div class="row pr-3 pb-2">           
            
            <app-date-range-picker class="ml-auto " [from]="startTime" [to]="endTime"
            (dateRangeSelected)="updateDateRange($event)"></app-date-range-picker>
            </div>
            <app-box-plot [loading]="loading1" [data]="boxPlotData"></app-box-plot>
        </div>
    </div>
</div>
<div class="card-group">
    <div class="card">
        <div class="card-body pl-0 pr-2 pb-0">
            <h5 class="card-title text-center">Bindata </h5>
            <div class="text-center loading-container">
                <fa-icon *ngIf="loading2" [icon]="['fas', 'spinner']" [spin]="true" size="2x"></fa-icon>
            </div>
            <app-bindata *ngIf="!loading2" [binData]="bindataTableData"></app-bindata>
        </div>
    </div>
</div>

<!-- <app-date-range-picker class="ml-auto" [from]="startTime" [to]="endTime"
    (dateRangeSelected)="updateDateRange($event)"></app-date-range-picker> -->