import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-with-input',
  templateUrl: './confirm-with-input.component.html',
  styles: [
  ]
})
export class ConfirmWithInputComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText : string;

  @Output() valueSubmitted : EventEmitter<any>  =new EventEmitter();
  email : string;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  submit(){
    this.valueSubmitted.emit(this.email);
    this.activeModal.close();
  }

}
